import { Link } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import React from "react";
import CardParcours from "../atoms/CardParcours";

const FeaturedParcours = ({
  parcoursTitle,
  parcoursText,
  parcoursButton,
  featuredParcours,
  addClass = null,
}) => {
  if (featuredParcours.length === 0) return;

  return (
    <section className={`mod bg-white full-width ${addClass}`}>
      <div className="grid-container">
        {parcoursTitle && (
          <h2 className="mb-2 letter-spacing underlined">{parcoursTitle}</h2>
        )}
        {parcoursText && (
          <div className="bloc--article__text mb-3">
            {renderRichText(parcoursText)}
          </div>
        )}
        <div className="mod--grid mt-2 mb-2">
          {featuredParcours.map((parcours, key) => (
            <CardParcours key={key} parcours={parcours} />
          ))}
        </div>
        {parcoursButton && (
          <Link className="button primary centered mb-0" to="/parcours">
            {parcoursButton}
          </Link>
        )}
      </div>
    </section>
  );
};

export default FeaturedParcours;
