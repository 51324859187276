import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Head from "../../components/head";
import Layout from "../../components/layout";
import ContactLink from "../../components/sections/ContactLink";
import FeaturedParcours from "../../components/sections/FeaturedParcours";
import HeroStandard from "../../components/sections/HeroStandard";

const Actualites = () => {
  const data = useStaticQuery(graphql`
    query {
      parcours: allContentfulParcours(sort: { order: ASC, fields: orderId }) {
        nodes {
          title
          slug
          accroche
          excerpt {
            excerpt
          }
          image {
            gatsbyImageData(
              layout: FIXED
              placeholder: BLURRED
              width: 370
              height: 220
            )
          }
          difficulty
          time
        }
      }
    }
  `);

  const parcours = data.parcours.nodes;
  const parcoursTitle = "Toutes les parcours de Strasbourg Running Tours";
  const parcoursText =
    "Découvrez les parcours, activités et circuits de Strasbourg Running Tours pour visiter Strasbourg en courant";
  return (
    <Layout>
      <Head title={parcoursTitle} description={parcoursText} />
      <HeroStandard title="Nos parcours pour visiter Strasbourg en courant" />

      {parcours.length > 0 ? (
        <FeaturedParcours
          parcoursTitle={parcoursText}
          featuredParcours={parcours}
        />
      ) : (
        <section className="mod">
          <h2 className="centered mb-3">
            Vous trouverez ici prochainement la liste des activités et parcours
            de Strasbourg Running Tours
          </h2>
        </section>
      )}
      <ContactLink />
    </Layout>
  );
};

export default Actualites;
