import React from "react";

const HeroStandard = ({ title }) => {
  return (
    <section className="block--hero full-width bg-dark-gray">
      <h1 className="text-center text-white mb-0">{title}</h1>
    </section>
  );
};

export default HeroStandard;
